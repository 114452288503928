@charset "UTF-8";
/* CSS Document */
@import "./common/extend";
/*
  ------------------------------------
  ####################################
   #top style
  ####################################
  ------------------------------------
*/
.main-visual{
    .bx-wrapper{
        border: none;
        margin-bottom: 0;
        .bx-viewport{
            li{
                img{
                    width: 100%;
                }
            }
        }
    }
}
/*
  ------------------------------------
  ####################################
   #top style
  ####################################
  ------------------------------------
*/
$site_size: 1200px; 
.bx-controls{
    display: none;
}
.wrapper{
    padding:5% 1% 0;
}
.main-contents{
    //padding: 20px 0 28px;
    //padding: 3.33% 0 0%;
}
.section-title-popular{}
.section-title-pickup{}
.section-title-newitems{}
.section-title-ranking{}

.item-list{
    //display: flex;
    // width: 100%;
    //-ms-flex-wrap: wrap;
    //flex-wrap: wrap;
    //-webkit-box-pack: justify;
    //-ms-flex-pack: justify;
    //justify-content: space-between;
    //margin-bottom: 66px;
    //margin-bottom: 5.5%;
    margin-bottom: 0%;
    li{
        width: 31.7982%;
        //margin-top: 43px;
        margin-bottom: 43px / $site_size * 100% ;
        margin-right: 2.3%;
        //margin: 10px 2.3% 10px 0;
        float: left;
        &:nth-child(3n){margin-right: 0}
        &:nth-child(3n+1){clear: both;}
        @include tublet{
            width: 49.0131%;
            margin-right: 1.9738%;
            
            &:nth-child(3n+1){clear: none;}
            &:nth-child(3n){margin-right: 1.9738%;}
            &:nth-child(2n){margin-right: 0;}
            
        }
        .item-list-img{
            a{
                display: block;
                img{
                    width: 100%;
                    //background-color: #fff;
                    box-sizing: border-box;
                    //border: 1px solid #ccc;
                    @include tublet{
                        padding: 5px;
                    }
                }
            }
        }
        .item-info{
            font-weight: bold;
            margin: 2px 0 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            .item-info-name{
                //font-size: 18px;
                font-size: 128.5714%;
                color: #333;
                margin-bottom: 0.1em;
                    @include tublet{
                        font-size: 3.8375vw;
                    }
                a{
                    color: #333;
                }
            }
            .item-info-text{
                //font-size: 13px;
                font-size: 100%;
                color: #666;
                margin-bottom: 0.1em;
                @include tublet{
                    font-size: 2.9875vw;
                }
                a{
                    color: #666;
                }
            }
            .item-info-price{
                //font-size: 14px;
                width: 100%;
                font-size: 128.57%;
                font-weight: bold; 
                color: #333;
                 @include tublet{
                    font-size: 2.9875vw;
                }
            }
        }
    }
}

.popular-list{
    margin-bottom: 5%;
    li{ 
        width: 49.0131%;
        margin-right: 1.9738%;
        &:nth-child(3n+1){clear: none;}
        &:nth-child(2n+1){clear: both;}
        &:nth-child(3n){margin-right: 1.9738%;}
        &:nth-child(2n){margin-right: 0;}
        //&:nth-child(5),&:nth-child(6){margin-bottom: 0;}
        &:nth-last-child(-n+2){margin-bottom: 0;}
        
        @include tublet{
            width: 100%;
            margin-right: 0;
            margin-bottom: 6.875%;
            &:nth-last-child(-n+2){margin-bottom: 6.875%;}
            &:last-child{margin-bottom: 0;}
        }
        .item-list-img{
            a{
                img{
                    border: none;
                }
            }
        }
    }
    .item-info{
            margin: 8px 0 0;
            .item-info-name{

            }
            .item-info-text{
      
            }
            .item-info-price{
            
            }
        }
}
.item-view-btn{
    margin-bottom: 5%;
    text-align: center;
    a{
        display: block;
        position: relative;
        font-size: 128.5714%;
        font-weight: 700;
        text-align: center;
        width:31.7982%;
        margin: 0 auto;
        padding: 1em 0;
        border-radius: 5px;
        background-color: #e00010;
        &:after{
            content:"";
            display: inline-block;
            width: 20px;
            height: 14px;
            background: url("https://gigaplus.makeshop.jp/kyotobin/assets/img/common/arrow1.png") no-repeat center center;
            background-size: contain;
            margin-left: 0.5em;
            vertical-align: middle;
            /*position:absolute;
			right:5%;
			top: 50%;
			transform: translate(0%,-50%);*/
        }
        &:link{color: #fff;}
        &:visited{color: #fff;}
        @include tublet{
            font-size: 3.1vw;
           min-width: 225px;
           padding: 1.5em 0;
        }
    }
}